<template>
  <div class="indexAbout">
    <div class="container container--xs">
      <div class="indexAbout__header">
        <h2 class="h1 indexAbout__header-title">
          Jaké kurzy tě čekají?
        </h2>
      </div>
      <div class="indexAbout__body">
        <p>
          Vybírat si můžeš z široké škály kurzů, dozvědět se můžeš mnohé o <mark>cloudových službách Microsoft 365</mark> a jejich zabezpečení. Osvojit si můžeš i základy <mark>datové analýzy</mark> anebo se ponořit do světa <mark>CRM a ERP</mark>.
        </p>
        <p>
          Pokud ti jde z toho všeho hlava kolem a nevíš, kde začít, využij jeden z našich studijních programů, ve kterém tě provedeme danou technologií postupně tak, aby se na nic nezapomnělo.
        </p>
      </div>
      <div class="indexAbout__footer">
        <BaseButton
            v-if="!loggedIn"
            :is-link="true"
            :href="'/registration'"
        >
          Zaregistruj se
        </BaseButton>
        <BaseButton
            v-else
            :is-link="true"
            :href="'/courses'"
        >
          Chci studovat
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import {mapGetters} from "vuex";

export default {
  components: {
    BaseButton,
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
}
</script>